import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import "./Apply.scss";

import Layout from "../components/Layout/Layout";

const Apply = ({ location }) => {
  const [fileData, setFileData] = useState([]);

  if (fileData.length > 3) {
    throw new Error("hello you are error");
  }
  function DashedDropZone() {
    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => {
          throw new Error("file reading was aborted");
        };
        reader.onerror = () => {
          throw new Error("file reading has failed");
        };
        reader.onload = () => {
          //   const binaryStr = reader.result;
        };
        reader.readAsArrayBuffer(file);

        setFileData(
          [
            ...fileData,
            acceptedFiles.map((el) => ({
              id: Math.random(),
              description: el.name,
              object: el,
            })),
          ].flat(),
        );
      });
    }, []);
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: ".pdf, .doc, .docx",
    });

    return (
      <>
        <div className="drop-zone-div" {...getRootProps()}>
          <input className="drop-zone-input" {...getInputProps()} />
          <p className="drop-zone">Drop your CV in PDF format here</p>
        </div>
        {fileData.map((el) => {
          return (
            <div className="drop-zone-file" key={el.id}>
              {el.description}
            </div>
          );
        })}
      </>
    );
  }
  return (
    <Layout>
      <div className="grid-container">
        <div className="apply">
          <h1>
            I wish to join S<span className="ampersand">&</span>T team!
          </h1>
          <p>
            Hoop on your S<span className="ampersand">&</span>T journey. Send us
            your offer today!
          </p>
          <div className="double-row">
            <label>
              <span>Name Surname</span>
              <input placeholder=" " />
            </label>
            <label>
              <span>E-mail</span>
              <input placeholder=" " />
            </label>
          </div>

          <div className="single-row">
            <span>
              Let us know which job would suit best to your further career
              development
            </span>
            <DashedDropZone maxSize={5242880} />
          </div>
          <div className="single-row">
            <label>
              <span>
                Let us know which job would suit best to your further career
                development
              </span>
              <textarea id="textarea" rows="4" cols="50" />
            </label>
            <button className="apply-button" type="button">
              Send
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Apply;
